// This is a skeleton starter React component generated by Plasmic.
// Feel free to edit as you see fit.
import HostUrlInput from "@/HostUrlInput";
import { AppCtx } from "@/wab/client/app-ctx";
import { parseProjectLocation } from "@/wab/client/cli-routes";
import { Spinner } from "@/wab/client/components/widgets";
import Select from "@/wab/client/components/widgets/Select";
import { useAsyncStrict } from "@/wab/client/hooks/useAsyncStrict";
import { ensure, spawnWrapper, swallow } from "@/wab/common";
import { ApiBranch, ApiProject, MainBranchId } from "@/wab/shared/ApiSchema";
import React from "react";
import { Modal } from "src/wab/client/components/widgets/Modal";

interface HostConfigProps {
  appCtx: AppCtx;
  project: ApiProject;
  onCancel: () => void;
  onUpdate: (canSkipRefresh: boolean) => Promise<void>;
  isRefreshingProjectData?: boolean;
}

export function HostConfig({
  appCtx,
  project,
  onCancel,
  onUpdate,
  isRefreshingProjectData,
}: HostConfigProps) {
  const [currentUrl, setCurrentUrl] = React.useState<string | null>(null);
  const [currentUrlWithoutProtocol, setCurrentUrlWithoutProtocol] =
    React.useState<string | null>(null);
  const parsedLocation = parseProjectLocation(appCtx.history.location);
  const team = appCtx.getAllTeams().find((t) => t.id === project.teamId);
  const showBranching =
    appCtx.appConfig.branching ||
    (project.teamId &&
      appCtx.appConfig.branchingTeamIds.includes(project.teamId)) ||
    (team?.parentTeamId &&
      appCtx.appConfig.branchingTeamIds.includes(team?.parentTeamId));
  const [branchName, setBranchName] = React.useState<string | MainBranchId>(
    !showBranching ? MainBranchId : parsedLocation?.branchName || MainBranchId
  );
  const [draft, setDraft] = React.useState<string | null>(
    currentUrlWithoutProtocol
  );
  const [protocol, setProtocol] = React.useState("https://");
  const [reloadDataCounter, setReloadDataCounter] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const draftUrl = protocol + draft;
  const invalidInput =
    draftUrl === currentUrl || !swallow(() => draft && new URL(draftUrl));

  const branchData = useAsyncStrict(
    async () =>
      (await appCtx.api.listBranchesForProject(project.id)).branches.filter(
        (branch) => branch.status === "active"
      ),
    [appCtx, project.id, reloadDataCounter]
  );

  React.useEffect(() => {
    const handleCurrentHostUrl = (hostUrl: string | null) => {
      const hostProtocol =
        (hostUrl && new URL(hostUrl).protocol + "//") ?? "https://";
      setProtocol(hostProtocol);
      const hostUrlWithoutProtocol =
        hostUrl?.substring(hostProtocol.length) ?? null;
      setCurrentUrlWithoutProtocol(hostUrlWithoutProtocol);
      setDraft(hostUrlWithoutProtocol);
      setCurrentUrl(hostUrl);
    };
    if (isRefreshingProjectData) {
      return;
    }
    if (branchName && branchName !== MainBranchId && branchData.value) {
      const branch = branchData.value.find((b) => b.name === branchName);
      if (branch) {
        handleCurrentHostUrl(branch.hostUrl);
        return;
      }
    }
    handleCurrentHostUrl(project.hostUrl);
  }, [branchData.value, branchName, project.hostUrl, isRefreshingProjectData]);

  const doUpdateUrl = async (
    branches: ApiBranch[],
    newHostUrl: string | null
  ) => {
    setIsLoading(true);
    await appCtx.api.updateHostUrl(project.id, {
      hostUrl: newHostUrl,
      branchId:
        branchName === MainBranchId
          ? null
          : ensure(
              branches.find((branch) => branch.name === branchName),
              () => `Couldn't find branch ${branchName}`
            ).id,
    });
    const canSkipRefresh = !!(
      parsedLocation &&
      parsedLocation.branchName &&
      branchName !== parsedLocation.branchName
    );
    await onUpdate(canSkipRefresh);
    if (canSkipRefresh) {
      // Refresh branch data
      setReloadDataCounter(reloadDataCounter + 1);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={`Configure host app for ${project.name}`}
      open
      onCancel={() => onCancel()}
      footer={null}
    >
      Plasmic projects can be hosted by your own app! <br />
      It allows you to bring your own components to Plasmic. Learn more about
      Plasmic app-hosting and how to setup your app{" "}
      <a href="https://www.plasmic.app/learn/app-hosting/" target="_blank">
        here
      </a>
      .
      <br />
      {!branchData.value ? (
        branchData.error ? (
          <div className="text-center mt-xxlg">
            Oops! Unexpected error loading the request. <br /> <br />
            Please try again later
          </div>
        ) : (
          <Spinner />
        )
      ) : (
        ((branches: ApiBranch[]) => (
          <>
            {showBranching && (
              <div className="flex-row gap-m fill-width flex-vcenter mt-lg">
                Project branch:{" "}
                <Select
                  className="flex-fill"
                  value={branchName}
                  onChange={(v) => v && setBranchName(v)}
                  isDisabled={branches.length === 0}
                >
                  <Select.Option key={MainBranchId} value={MainBranchId}>
                    Main branch
                  </Select.Option>
                  {branches.map((branch) => (
                    <Select.Option key={branch.name} value={branch.name}>
                      {branch.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            <HostUrlInput
              className="mv-xlg"
              data-test-id="host-url-input"
              hostProtocolSelect={{
                key: protocol,
                value: protocol,
                onChange: (newVal) => {
                  setProtocol(newVal!);
                },
              }}
              urlInput={{
                props: {
                  value: draft || "",
                  onChange: (e) => {
                    let value = e.currentTarget.value || null;
                    ["http://", "https://"].forEach((p) => {
                      if (value && value.startsWith(p)) {
                        value = value.substring(p.length);
                        setProtocol(p);
                      }
                    });
                    setDraft(value);
                  },
                  ...(currentUrlWithoutProtocol
                    ? { placeholder: currentUrlWithoutProtocol }
                    : {}),
                },
              }}
              confirmButton={
                !isLoading && !isRefreshingProjectData
                  ? {
                      props: {
                        onClick: spawnWrapper(async () => {
                          if (invalidInput) {
                            return;
                          }
                          const domain = new URL(
                            ensure(draftUrl, "Unexpected URL")
                          ).origin;
                          await doUpdateUrl(branches, draftUrl);
                          await appCtx.api.addTrustedHost(domain);
                        }),
                        disabled: invalidInput,
                      },
                    }
                  : { props: { children: "Loading", disabled: true } }
              }
              clearButton={{
                onClick: spawnWrapper(async () => {
                  if (!project.hostUrl) {
                    return;
                  }
                  await doUpdateUrl(branches, null);
                  setDraft(null);
                }),
                disabled: !project.hostUrl || isLoading,
              }}
            />
            Remember to only use trusted hosts for your projects.
          </>
        ))(branchData.value)
      )}
    </Modal>
  );
}
